import { useState } from 'react';
import './App.css';
import Alert from './components/Alert';
import About from './components/About';
import Navbar from './components/Navbar';
import Textform from './components/Textform';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function App() {
  const[mode,setMode] = useState('light'); //whether the dark mode is enabled or not
  const[alert,setAlert] = useState(null)
  const showAlert=(message,type)=>{
    setAlert({
      message: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null)
    }, 1500);
  }

  const removeBodyClass=()=>{
    document.body.classList.remove('bg-light')
    document.body.classList.remove('bg-dark')
    document.body.classList.remove('bg-primary')
    document.body.classList.remove('bg-danger')
    document.body.classList.remove('bg-success')
    document.body.classList.remove('bg-warning')
    document.body.classList.remove('bg-white')
  }

  const toggleMode=(clsPallet)=>{
    removeBodyClass();
    console.log(clsPallet)
    document.body.classList.add('bg-'+clsPallet)
    if(mode==='light'){
      setMode('dark');
      document.body.style.backgroundColor='#0e3141';
      showAlert('Dark mode has been enabled','success')
      //document.title = 'Dark mode enabled' (not a good practice)
    }
    else{
      setMode('light');
      document.body.style.backgroundColor='white';
      showAlert('Light mode has been enabled','success')
      //document.title = 'Dark mode enabled' (not a good practice)
    }
  }
  return (
  <>
<Router>
<Navbar title='TextUtils'mode={mode} toggleMode={toggleMode}/>
<Alert alert = {alert}/>
<div className="container my-3"> 
<Routes>
  <Route exact path='/' element={<Textform showAlert={showAlert} mode={mode} heading='Try TextUtils - Word Counter, Character Counter'/>}/>
  <Route exact path='About'element={<About mode={mode}/>}/>
</Routes>
</div>
</Router>
  </>
  );
 
}


export default App;

import React,{useState} from 'react' //react functional based component (RFC)

export default function Textform(props) {

  const handleUpClick = ()=>{
    console.log("Uppercase was clicked "+text)
    let new_text = text.toUpperCase()
    setText(new_text)
    props.showAlert('Converted to upper case','success');
  }
  const handleLoClick = ()=>{
    let small_text = text.toLowerCase()
    setText(small_text)
    props.showAlert('Converted to lower case','success');
  }
  const handleVowel = ()=>{
    let symbol = '*'
    let new_text = ''
    for(let i = 0;i<=text.length;i++){
      if(text[i] === 'a' || text[i] === 'e' || text[i] === 'i' || text[i] === 'o' || text[i] === 'u'){
        new_text+=symbol
      }
      else{
        new_text+=text[i]
      }
    }
    props.showAlert(new_text,'success');

  }
  const clearText = ()=>{
    let new_text = ''
    setText(new_text)
    props.showAlert('Text cleared','success');
  }
  const handleOnChange = (event)=>{
    console.log("On Change "+text)
    setText(event.target.value)
  }
  const [text, setText] = useState('Enter text here');
  return (
    <>
    <div className='container'style={{color: props.mode==='dark'?'white':'black'}}>
        <h1 className='mb-4'>{props.heading}</h1>
        <div className="mb-3">
        <textarea className="form-control" value = {text} onChange={handleOnChange} style={{backgroundColor: props.mode==='dark'?'#0e3141':'white',color: props.mode==='dark'?'white':'black'}} id="myBox" rows="8"></textarea>
        </div>
        <button disabled={text.length===0} className="btn btn-primary mx-2 my-1" onClick={handleUpClick}>Convert to upper case</button>
        <button disabled={text.length===0} className='btn btn-primary mx-2 my-1' onClick={handleLoClick}>Convert to lower case</button>
        <button disabled={text.length===0} className='btn btn-primary mx-2 my-1' onClick={handleVowel}>Convert Vowel to *</button>
        <button disabled={text.length===0} className='btn btn-primary mx-2 my-1' onClick={clearText}>Clear Text</button>
    </div>
    <div className='container my-3'style={{color: props.mode==='dark'?'white':'black'}}>
      <h1>Your text summary</h1>
      <p>{text.split(' ').filter((element)=>{return element.length!==0}).length} words and {text.length} characters</p>
      <p>{0.008 * text.split(' ').filter((element)=>{return element.length!==0}).length}Minutes to read</p>
      <h2>Preview</h2>
      <p>{text.length>0?text:'Nothing to preview!'}</p>
    </div>
    </>
  )
}

